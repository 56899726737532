.about__content {
    flex: 1;
    margin-bottom: 0;
}

@supports(display: grid) {
    .about__content {
        margin-bottom: 1.5rem;
    }
}

.about__links {
    text-align: right;
}

.about__email {
    margin-right: 1.25rem;
}

.contact__nav {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.contact__header {
    margin-top: 0;
}
.contact__item {
    margin-right: 0.5rem;
}
.contact__item:last-child {
    margin-right: 0rem;
}
.link--icon>* {
    vertical-align: middle;
}

.link__icon {
    height: 1.65rem;
    fill: inherit;
}

.link--primary {
    color: var(--window-text-color);
    fill: var(--window-text-color);
    transition: fill ease-in-out 100ms, color ease-in-out 100ms;
}

.link--primary:hover {
    color: var(--link-hover-color);
    fill: var(--link-hover-color);
}


.floating {
    box-shadow: 2px 2px 6px -3px rgba(0, 0, 0, .75);
}

.window {
    position: relative;
    padding: 1rem;
    border: 1px solid var(--window-border-color);
    border-radius: 3px;
}

#theme-toggle__button {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    right: 0;
    transform: translateY(calc(-100% - 6px));
    cursor: pointer;

    background-color: var(--window-bg-color);
    color: var(--window-text-color);
    border: 1px solid var(--window-border-color);
    border-radius: 3px;
    transition: border-color .1s ease-in-out,color .1s ease-in-out;
}

#theme-toggle__button:hover {
    border-color: var(--link-hover-color);
}

#theme-toggle__button > svg {
    transition: color .1s ease-in-out;
}

#theme-toggle__button:hover > svg {
    color: var(--link-hover-color);
}

